import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const HappeningInCommunityDesktop1 =
  './assets/HappeningInCommunityDesktop1.png';
const HappeningInCommunityDesktop2 =
  './assets/HappeningInCommunityDesktop2.png';
const HappeningInCommunityDesktop3 =
  './assets/HappeningInCommunityDesktop3.png';

const HappeningInCommunityMobile1 = './assets/HappeningInCommunityMobile1.png';
const HappeningInCommunityMobile2 = './assets/HappeningInCommunityMobile2.png';
const HappeningInCommunityMobile3 = './assets/HappeningInCommunityMobile3.png';
const HappeningInCommunityMobile4 = './assets/HappeningInCommunityMobile4.png';
const HappeningInCommunityMobile5 = './assets/HappeningInCommunityMobile5.png';
const HappeningInCommunityMobile6 = './assets/HappeningInCommunityMobile6.png';

const happeningInCommunityDataDesktop = [
  {
    id: 1,
    image: (
      <StaticImage
        src={HappeningInCommunityDesktop1}
        placeholder="blurred"
        style={{ height: '100%' }}
        objectFit="contain"
        alt="community"
      />
    )
  },
  {
    id: 2,
    image: (
      <StaticImage
        src={HappeningInCommunityDesktop2}
        placeholder="blurred"
        style={{ height: '100%' }}
        objectFit="contain"
        alt="community"
      />
    )
  },
  {
    id: 3,
    image: (
      <StaticImage
        src={HappeningInCommunityDesktop3}
        placeholder="blurred"
        style={{ height: '100%' }}
        objectFit="contain"
        alt="community"
      />
    )
  }
];

const happeningInCommunityDataMobile = [
  {
    id: 1,
    image: (
      <StaticImage
        src={HappeningInCommunityMobile1}
        placeholder="blurred"
        style={{ height: '100%' }}
        objectFit="contain"
        alt="community"
      />
    )
  },
  {
    id: 2,
    image: (
      <StaticImage
        src={HappeningInCommunityMobile2}
        placeholder="blurred"
        style={{ height: '100%' }}
        objectFit="contain"
        alt="community"
      />
    )
  },
  {
    id: 3,
    image: (
      <StaticImage
        src={HappeningInCommunityMobile3}
        placeholder="blurred"
        style={{ height: '100%' }}
        objectFit="contain"
        alt="community"
      />
    )
  },
  {
    id: 4,
    image: (
      <StaticImage
        src={HappeningInCommunityMobile4}
        placeholder="blurred"
        style={{ height: '100%' }}
        objectFit="contain"
        alt="community"
      />
    )
  },
  {
    id: 5,
    image: (
      <StaticImage
        src={HappeningInCommunityMobile5}
        placeholder="blurred"
        style={{ height: '100%' }}
        objectFit="contain"
        alt="community"
      />
    )
  },
  {
    id: 6,
    image: (
      <StaticImage
        src={HappeningInCommunityMobile6}
        placeholder="blurred"
        style={{ height: '100%' }}
        objectFit="contain"
        alt="community"
      />
    )
  }
];

export { happeningInCommunityDataDesktop, happeningInCommunityDataMobile };
