import GatsbyLink from 'gatsby-link';
import styled from 'styled-components/macro';
import colors from '../../../assets/colors';
import fontSize from '../../../assets/fontSize';

export const TitleWrapper = styled.div`
  width: 68%;
  margin-right: 1rem;
  @media (max-width: 740px) {
    flex: 1;
    width: unset;
    margin-right: unset;
    margin-top: 1rem;
  }
`;
export const Link = styled(GatsbyLink)`
  text-decoration: none;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding-right: 1rem;
  @media (max-width: 740px) {
    padding-right: 0;
  }
`;
export const Container = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 925px) {
    flex-direction: column;
  }
`;
export const RightContainer = styled.div`
  flex: 2;
  margin: auto;
  padding: 0px 16px 0px 16px;
  display: flex;
  @media (max-width: 925px) {
    flex-direction: column;
    padding-bottom: 2rem;
  }
`;
export const ButtonContainer = styled.div`
  flex: 1;
  margin-left: -10px;
  margin-top: -15px;
  margin: auto;
  display: flex;
  flex-direction: column;
  .btn {
    max-width: 217px;
    margin-left: 10px;
    @media only screen and (max-width: 890px) {
      height: 42px !important;
      width: 190px;
      margin-top: 10px;
    }
  }
  .clear-btn {
    background: no-repeat;
    border: 2px solid #05b4ad;
    color: white;
    margin-top: 16px;
    @media only screen and (max-width: 890px) {
      margin-top: 10px;
    }
  }
`;
export const Title = styled.h1`
  font-size: ${fontSize.twoRem};
  line-height: 44px;
  color: ${props => (props.black ? colors.black : colors.white)};
  margin-bottom: 0;
  margin-top: 0;
  @media (max-width: 740px) {
    text-align: center;
  }
  @media (max-width: 668px) {
    font-weight: 800;
    font-size: 19.2px;
    text-align: center;
    line-height: 26px;
  }
`;
export const SubText = styled.h1`
  font-size: 21px;
  color: ${props => (props.black ? colors.black : colors.white)};
  font-weight: 300;
  @media (max-width: 740px) {
    text-align: center;
  }
  @media (max-width: 668px) {
    font-size: 16px;
    text-align: center;
  }
`;
export const ImageWrapper = styled.div`
  width: 21rem;

  img {
    border-radius: 21px 0 0 21px;
  }

  @media (max-width: 925px) {
    width: unset;

    img {
      border-radius: 21px 21px 0 0;
    }
  }
`;
