import React, { useState, useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import styled from 'styled-components/macro';
import Layout from '../components/Layout';
import TopHeader from '../views/shared/TopHeaderV2';
import MeetGoGetters from '../views/CommunityPage/MeetGoGetters';
import MakeDifference from '../views/CommunityPage/MakeDifference';
import HappeningInCommunity from '../views/CommunityPage/HappeningInCommunity';
import StayUpdated from '../views/CommunityPage/StayUpdated';

import GogetPartners from '../views/BusinessPage/GogetPartners';

import HeaderSeo from '../components/HeaderSeo';

import Banner from '../views/shared/Banner';

import MetaImg from '../assets/img/meta/index.png';

import BackgroundDesktop from '../assets/img/community/BackgroundDesktop.jpg';
import BackgroundMobile from '../assets/img/community/BackgroundMobile.jpg';

import colors from '../assets/colors';

const Merchandise = '../assets/img/merchandise.svg';
const MainDesktopBanner = '../assets/img/community/MainBannerDesktop.jpg';
const MainBannerMobile = '../assets/img/community/MainBannerMobile.jpg';

const WrapperBackground = styled.div`
  background-image: url(${BackgroundDesktop});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  padding-bottom: 9rem;
  padding-top: 3rem;
  @media (max-width: 760px) {
    background-image: url(${BackgroundMobile});
  }
`;
const BannerWrap = styled.div`
  margin-bottom: 100px;
`;

const Community = props => {
  const [mobile, setMobile] = useState(false);
  const { location } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('resize', sizeHandler);
    return sizeHandler();
  }, [mobile]);

  const sizeHandler = () => {
    if (window.innerWidth <= 800) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  return (
    <>
      <HeaderSeo
        title="GoGet | Connecting the city with GoGetters"
        description="Find out how GoGetters are helping others and giving back."
        image={MetaImg}
        keywords=""
        pathname={location.pathname}
      />
      <Layout>
        <TopHeader
          backgroundImg={
            <StaticImage
              src={MainDesktopBanner}
              loading="eager"
              style={{ height: '100%' }}
              quality={100}
              objectPosition="left top"
              placeholder="blurred"
              alt="GoGet Community"
            />
          }
          mobileBackgroundImg={
            <StaticImage
              src={MainBannerMobile}
              loading="eager"
              style={{ width: '100%' }}
              objectFit="contain"
              placeholder="blurred"
              alt="GoGet Community"
            />
          }
          h4="Community"
          showVideo={true}
          videoTitle="Connecting the city with GoGetters"
          videoSrcURL="https://player.vimeo.com/video/482442599"
          color={colors.black}
          title={
            <>
              Meet our <br /> Community
            </>
          }
          description="Find out how GoGetters are helping others and giving back."
          backgroundMobileHeight="47rem"
          main
          playiconmargintop={{ marginTop: '17rem' }}
        />
        <MeetGoGetters />
        <WrapperBackground>
          <GogetPartners isHyperLink title="How our community has helped" />
          <MakeDifference />
        </WrapperBackground>
        <HappeningInCommunity />
        <StayUpdated />
        <BannerWrap>
          <Banner
            backgroundColor="#ffd601"
            image={
              <StaticImage
                src={Merchandise}
                style={{ height: '100%', width: '100%' }}
                objectFit="contain"
                quality={100}
                placeholder="blurred"
                alt=""
              />
            }
            mobileImage={
              <StaticImage
                src={Merchandise}
                style={{ height: '100%', width: '100%' }}
                objectFit="contain"
                quality={100}
                placeholder="blurred"
                alt=""
              />
            }
            title="Get your Exclusive GoGet merchandises here!"
            btn1={{
              text: 'SHOP NOW',
              link: 'https://www.photobook.com.my/goget',
              type: 'primary',
              btnProps: {
                target: '_blank',
                rel: 'noreferrer noopener'
              }
            }}
            black
          />
        </BannerWrap>
      </Layout>
    </>
  );
};

export default Community;
