import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import TitleHeaderText from '../../components/TitleHeaderText';
import MakeDifferenceCard from '../../views/shared/MakeDifferenceCard';
import {
  kechara,
  huckleberry,
  biji,
  malaysianRedCrescent,
  lyf
} from './const/makeDifferenceData';
import Slider from '../../views/LandingPage/Slider';

import InformativeSliderModal from '../shared/InformativeSliderModal';

import colors from '../../assets/colors';

const MainDiv = styled.div`
  justify-content: center;
  flex-wrap: wrap;
  width: 1050px;
  margin: auto;
  @media (max-width: 1155px) {
    width: unset;
    margin: unset;
  }
  .flickity-prev-next-button.previous {
    left: -46px;
    background: ${colors.white};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    @media only screen and (max-width: 1155px) {
      display: none;
    }
  }
  .flickity-prev-next-button.next {
    right: -46px;
    background: ${colors.white};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    @media only screen and (max-width: 1155px) {
      display: none;
    }
  }

  .flickity-page-dots .dot {
    background: ${colors.primary};
  }
`;

const MakeDifference = () => {
  const [isMobile, setMobile] = useState(false);
  const [open, setOpenModal] = useState(false);
  const [number, setNumber] = useState(0);

  useEffect(() => {
    sizeHandler();
    window.addEventListener('resize', sizeHandler);
  }, []);

  const sizeHandler = () => {
    if (window.innerWidth <= 760) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  let organisations = [];
  if (isMobile) {
    organisations = [kechara, huckleberry, biji, malaysianRedCrescent, lyf];
  } else {
    organisations = [huckleberry, biji, malaysianRedCrescent, lyf, kechara];
  }

  const options = {
    wrapAround: true,
    freeScroll: false,
    fullscreen: true,
    adaptiveHeight: true,
    draggable: true,
    pageDots: true,
    contain: true,
    prevNextButtons: true
  };

  const handleEventModal = result => {
    if (isMobile) {
      setNumber(result - 1);
    } else {
      switch (result) {
        case 1:
          setNumber(4);
          break;
        case 2:
          setNumber(0);
          break;
        case 3:
          setNumber(1);
          break;
        case 4:
          setNumber(2);
          break;
        case 5:
          setNumber(3);
          break;
        default:
          break;
      }
    }
    setOpenModal(!!result);
  };

  return (
    <MainDiv scroll={true}>
      <TitleHeaderText
        styledDesktop={{ marginBottom: '47px', paddingTop: '10rem' }}
        styledMobile={{ marginLeft: '1rem', marginRight: '1rem' }}
        text="Making a difference"
      />
      <Slider options={options}>
        {organisations &&
          organisations.map(data => {
            return (
              <MakeDifferenceCard
                key={data.id}
                id={data.id}
                image={data.image}
                title={data.title}
                description={data.description}
                handleModal={handleEventModal}
              />
            );
          })}
      </Slider>
      {open && (
        <InformativeSliderModal
          image={organisations[number]?.image}
          title={organisations[number]?.title}
          description={organisations[number]?.description}
          bodyTitle={organisations[number]?.bodyTitle}
          bannerImages={organisations[number]?.bannerImages}
          bodyDescription={organisations[number]?.bodyDescription}
          handleModal={handleEventModal}
          open={open}
        />
      )}
    </MainDiv>
  );
};

export default MakeDifference;
