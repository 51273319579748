import React from 'react';
import styled from 'styled-components/macro';

import TitleHeaderText from '../../components/TitleHeaderText';

import FacebookIcon from './const/assets/facebookIconTeal.svg';
import InstagramIcon from './const/assets/instagramIconTeal.svg';

const MainDiv = styled.div`
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1050px;
  margin: 0 auto;
  width: 90%;
`;
const Wrapper = styled.div`
  display: flex;
  flex-items: center;
  width: 220px;
  justify-content: space-between;
  margin: auto;
  padding-top: 30px;
  @media only screen and (max-width: 500px) {
    width: 150px;
    padding-top: 20px;
  }
`;
const Icon = styled.img`
  width: 56px;
  height: 56px;
  cursor: pointer;
  @media only screen and (max-width: 500px) {
    width: 45px;
    height: 45px;
  }
`;
const Link = styled.a`
  text-decoration: none;
`;

const HappeningInCommunity = () => {
  return (
    <MainDiv>
      <TitleHeaderText
        styledDesktop={{ fontSize: '19.2px', lineHeight: '32px' }}
        styledMobile={{ marginLeft: '1rem', marginRight: '1rem' }}
        text="Stay updated with our community here"
      />
      <Wrapper>
        <Link href="https://www.instagram.com/gogetmy/?hl=en">
          <Icon
            src={InstagramIcon}
            alt="instagram icon"
            className="addMargin"
          />
        </Link>
        <Link href="https://www.facebook.com/GoGet.my/">
          <Icon src={FacebookIcon} alt="facebook icon" />
        </Link>
      </Wrapper>
    </MainDiv>
  );
};

export default HappeningInCommunity;
