import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import styled from 'styled-components/macro';
import colors from '../../../assets/colors';
import fontSize from '../../../assets/fontSize';

const BannerImgWrapper = styled.div`
  border-radius: 10px 10px 0px 0px;
  width: 100%;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  font-weight: bold;
  padding-left: 10px;
  margin-top: 6px;
  img {
    margin-right: 8px;
    height: 40px;
  }
`;
const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;

  .MuiSvgIcon-root {
    color: ${props => props.active && colors.primary};
  }
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: self-end;
  }
`;
const ModalContainer = styled.div`
  padding: 10px 22px 10px 22px;
  span {
    font-weight: 400;
    font-size: ${fontSize.large};
    margin-top: 5px;
    display: inline-block;
  }
  quote {
    color: ${colors.secondary};
    font-size: ${fontSize.large};
    font-style: italic;
  }
  a {
    color: ${colors.primary};
    text-decoration: unset;
  }
`;
const ModalHeader = styled(Header)`
  align-items: center;

  @media (max-width: 480px) {
    flex-direction: column;
  }
`;
const SliderDiv = styled.div`
  margin-bottom: 5px;
  .slick-dots li button:before {
    font-size: 10px;
    line-height: 10px;
    color: #00b4ad;
    margin-top: 2px;
  }
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    display: flex;
    align-items: center;
  }
  .slick-next {
    display: none !important;
  }
  .iframe {
    height: 300px;
  }
`;
const LogoWrapper = styled.div`
  margin-right: 24px;
`;

const InformativeSliderModal = ({
  image,
  title,
  description,
  bodyTitle,
  bodyDescription,
  handleModal,
  open,
  bannerImages
}) => {
  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true
  };

  return (
    <Dialog
      open={open}
      fullWidth={true}
      scroll={'body'}
      onClose={() => handleModal(false)}
      PaperProps={{
        style: { borderRadius: 10 }
      }}
    >
      <div>
        <CloseIcon
          onClick={() => handleModal(false)}
          style={{
            position: 'absolute',
            top: '4px',
            bottom: '0',
            right: '7px',
            zIndex: '1',
            color: 'white',
            cursor: 'pointer'
          }}
        />
        <SliderDiv>
          <Slider {...settings}>
            {bannerImages.map((image, i) => (
              <BannerImgWrapper key={i}>{image}</BannerImgWrapper>
            ))}
          </Slider>
        </SliderDiv>
        <ModalContainer>
          <FlexDiv>
            <ModalHeader>
              <LogoWrapper>{image}</LogoWrapper>
              <div>
                {title}
                <br />
                <span> {description} </span>
              </div>
            </ModalHeader>
          </FlexDiv>
          <br />
          <Divider />
          <br />
          <div>
            <span style={{ color: colors.secondary }}>
              <b> {bodyTitle} </b>
            </span>
            <span> {bodyDescription} </span>
          </div>
          <br />
        </ModalContainer>
      </div>
    </Dialog>
  );
};

export default InformativeSliderModal;
