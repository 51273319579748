import React from 'react';
import styled from 'styled-components/macro';

import Paper from '@material-ui/core/Paper';

import colors from '../../../assets/colors';
import fontSize from '../../../assets/fontSize';

const StyledPaper = styled(Paper)`
  width: 244px;
  height: 254px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  border-radius: 20px !important;
  background-color: ${colors.blueLight} !important;
  text-align: left;
  margin: 0px 57px 20px 0px;
  @media (max-width: 760px) {
    width: 206px;
    height: 289px;
    margin: 0px 70px 20px 0px;
  }
`;
const FlexDiv = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 1rem;
  height: 60px;
`;
const Bold = styled.span`
  font-weight: 700;
`;
const Text = styled.p`
  font-family: 'Open Sans', sans-serif !important;
  font-size: ${fontSize.large};
  line-height: 19px;
  font-weight: normal;
`;
const AvatarWrap = styled.div`
  width: 112px;
  height: 112px;
  position: relative;
  bottom: 60px;
`;

const MeetGoGettersCard = ({ id, image, name, description }) => (
  <StyledPaper key={id}>
    <FlexDiv>
      <AvatarWrap>{image}</AvatarWrap>
    </FlexDiv>
    <Text>
      <Bold>{name}</Bold>
    </Text>
    <Text>{description}</Text>
  </StyledPaper>
);

export default MeetGoGettersCard;
