import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import TitleHeaderText from '../../components/TitleHeaderText';
import MeetGoGettersCard from '../shared/MeetGoGettersCard';
import {
  rosman,
  nala,
  see,
  woo,
  syafik,
  ahmadSyamil,
  hazli,
  shukery,
  desmond,
  helemie,
  nadzrul,
  elsa
} from './const/meetGogettersData';

import Slider from '../../views/LandingPage/Slider';

import colors from '../../assets/colors';

const MainDiv = styled.div`
  justify-content: center;
  flex-wrap: wrap;
  width: 1050px;
  margin: auto;
  margin-bottom: 5rem;
  @media (max-width: 1155px) {
    width: 85%;
  }
  @media (max-width: 760px) {
    margin: unset;
    width: unset;
  }
  .flickity-viewport {
    padding-top: 3rem;
  }
  .flickity-prev-next-button.previous {
    left: -46px;
    background: ${colors.white};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    @media (max-width: 760px) {
      left: 15px;
      height: 39px;
      width: 39px;
    }
  }
  .flickity-prev-next-button.next {
    right: -46px;
    background: ${colors.white};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    @media (max-width: 760px) {
      right: 15px;
      height: 39px;
      width: 39px;
    }
  }
`;

const options = {
  wrapAround: true,
  freeScroll: false,
  fullscreen: true,
  adaptiveHeight: true,
  draggable: true,
  pageDots: false,
  contain: true,
  prevNextButtons: true
};

function MeetGoGetters() {
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    sizeHandler();
    window.addEventListener('resize', sizeHandler);
  }, []);

  const sizeHandler = () => {
    if (window.innerWidth <= 760) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  let meetGogetters = [];
  if (isMobile) {
    meetGogetters = [
      rosman,
      nala,
      see,
      woo,
      syafik,
      ahmadSyamil,
      hazli,
      shukery,
      desmond,
      helemie,
      nadzrul,
      elsa
    ];
  } else {
    meetGogetters = [
      nala,
      see,
      woo,
      syafik,
      ahmadSyamil,
      hazli,
      shukery,
      desmond,
      helemie,
      nadzrul,
      elsa,
      rosman
    ];
  }
  return (
    <MainDiv>
      <TitleHeaderText
        styledMobile={'margin-left: 1rem; margin-right: 1rem;'}
        text="Meet fellow GoGetters"
      />
      <Slider options={options}>
        {meetGogetters &&
          meetGogetters.map(data => (
            <MeetGoGettersCard
              image={data.image}
              name={data.name}
              description={data.description}
              key={data.id}
            />
          ))}
      </Slider>
    </MainDiv>
  );
}

export default MeetGoGetters;
