import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import TitleHeaderText from '../../components/TitleHeaderText';
import {
  happeningInCommunityDataDesktop,
  happeningInCommunityDataMobile
} from './const/happeningInCommunityData';
import Slider from '../../views/LandingPage/Slider';

import colors from '../../assets/colors';

const MainDiv = styled.div`
  justify-content: center;
  flex-wrap: wrap;
  width: 1050px;
  margin: auto;
  @media (max-width: 1155px) {
    width: unset;
    margin: unset;
  }
  .flickity-prev-next-button.previous {
    left: -46px;
    background: ${colors.white};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    @media only screen and (max-width: 1155px) {
      display: none;
    }
  }
  .flickity-prev-next-button.next {
    right: -46px;
    background: ${colors.white};
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    @media only screen and (max-width: 1155px) {
      display: none;
    }
  }

  .flickity-page-dots .dot {
    background: ${colors.primary};
  }
`;
const ImageWrapper = styled.div`
  width: 739px;
  height: 400px;
  margin: 0px 160px 20px 0px;
  @media only screen and (max-width: 760px) {
    width: 22rem;
    height: 452px;
  }
  @media only screen and (max-width: 360px) {
    width: 20rem;
    margin: 0px 60px 20px 0px;
  }
`;

const HappeningInCommunity = () => {
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    sizeHandler();
    window.addEventListener('resize', sizeHandler);
  }, []);

  const sizeHandler = () => {
    if (window.innerWidth <= 760) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  const options = {
    wrapAround: true,
    freeScroll: false,
    fullscreen: true,
    adaptiveHeight: true,
    draggable: true,
    pageDots: true,
    contain: true,
    prevNextButtons: true
  };
  const images = isMobile
    ? happeningInCommunityDataMobile
    : happeningInCommunityDataDesktop;
  return (
    <MainDiv>
      <TitleHeaderText
        styledDesktop={{ marginBottom: '47px' }}
        styledMobile={{ marginLeft: '1rem', marginRight: '1rem' }}
        text="Happenings in the community"
      />
      <Slider options={options}>
        {images &&
          images.map(data => {
            return <ImageWrapper key={data.id}>{data.image}</ImageWrapper>;
          })}
      </Slider>
    </MainDiv>
  );
};

export default HappeningInCommunity;
