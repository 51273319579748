import React, { useState, useEffect } from 'react';
import ActivityCard from '../ActivityCard';
import Button from '../../../components/Button';
import {
  Container,
  RightContainer,
  Title,
  SubText,
  ButtonContainer,
  ImageWrapper,
  Link,
  TitleWrapper
} from './styles';

function Banner({
  backgroundColor,
  image,
  title,
  subText,
  btn1,
  btn2,
  mobileImage,
  black
}) {
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {
    window.addEventListener('resize', sizeHandler);
    return sizeHandler();
  }, [isMobile]);

  const sizeHandler = () => {
    if (window.innerWidth <= 925) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', marginLeft: '-4px' }}
    >
      <ActivityCard
        customStyle={{
          marginTop: '100px',
          minHeight: '195px',
          width: '100%',
          backgroundColor: backgroundColor,
          justifyContent: 'center',
          maxWidth: isMobile && '350px'
        }}
      >
        <Container>
          <ImageWrapper>{isMobile ? mobileImage : image}</ImageWrapper>
          <RightContainer>
            <TitleWrapper>
              <Title black={black}> {title} </Title>
              <SubText black={black}> {subText} </SubText>
            </TitleWrapper>
            <ButtonContainer>
              {btn1 && (
                <Link to={btn1.link} {...btn1?.btnProps}>
                  <Button
                    noshadow={black ? 'true' : undefined}
                    className="btn"
                    primary
                    background={btn1.type}
                    onClick={() =>
                      window.analytics.track('body_clickthrough', {
                        category: 'clickthrough',
                        label: btn1.text ? btn1.text : '',
                        destination: btn1.link ? btn1.link : ''
                      })
                    }
                  >
                    {' '}
                    {btn1.text}{' '}
                  </Button>
                </Link>
              )}
              {btn2 && (
                <Link to={btn2.link}>
                  <Button
                    noshadow={black ? 'true' : undefined}
                    className={`btn ${btn2.type}`}
                    primary
                    onClick={() =>
                      btn2.onClick &&
                      window.analytics.track('body_clickthrough', {
                        category: 'clickthrough',
                        label: btn2.text ? btn2.text : '',
                        destination: btn2.link ? btn2.text : btn2.link
                      })
                    }
                  >
                    {' '}
                    {btn2.text}{' '}
                  </Button>
                </Link>
              )}
            </ButtonContainer>
          </RightContainer>
        </Container>
      </ActivityCard>
    </div>
  );
}

export default Banner;
