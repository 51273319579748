import React from 'react';
import styled from 'styled-components/macro';

import Paper from '@material-ui/core/Paper';
import { KeyboardArrowRight } from '@material-ui/icons';
import colors from '../../../assets/colors';
import fontSize from '../../../assets/fontSize';

const StyledPaper = styled(Paper)`
  width: 248px;
  height: 247px;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
  padding-top: 2rem;
  border-radius: 20px !important;
  box-shadow: 0px 4px 6px rgba(206, 206, 206, 0.5) !important;
  text-align: center;
  margin: 0px 60px 20px 0px;
`;
const Title = styled.h4`
  font-family: 'Open Sans', sans-serif;
  font-size: ${fontSize.xLarge};
  font-weight: 700;
`;
const Text = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: ${fontSize.large};
  font-weight: normal;
`;
const AvatarWrapper = styled.div`
  margin: 0 auto;
  height: 75px;
`;
const Arrow = styled(KeyboardArrowRight)`
  margin-left: 1rem !important;
  color: ${colors.primary} !important;
`;
const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  color: ${colors.primary};
  justify-content: center;
  cursor: pointer;
`;
const Wrapper = styled.div`
  height: 100px;
`;

const MakeDifferenceCard = ({ id, image, title, description, handleModal }) => {
  const handleClick = () => {
    handleModal(id);
  };
  return (
    <StyledPaper key={id} onClick={handleClick}>
      <AvatarWrapper>{image}</AvatarWrapper>
      <Wrapper>
        <Title>{title}</Title>
        <Text>{description}</Text>
      </Wrapper>
      <FlexDiv>
        <Text>READ MORE</Text>
        <Arrow />
      </FlexDiv>
    </StyledPaper>
  );
};

export default MakeDifferenceCard;
