import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const Biji1 = './assets/BijiBiji1.jpg';
const Biji2 = './assets/BijiBiji2.jpg';
const Biji3 = './assets/BijiBiji3.jpg';
const Kechara1 = './assets/Kechara1.jpg';
const Kechara2 = './assets/Kechara2.jpg';
const Kechara3 = './assets/Kechara3.jpg';
const LittleYellowFlowerEducationFoundation1 =
  './assets/LittleYellowFlowerEducationFoundation1.jpg';
const LittleYellowFlowerEducationFoundation2 =
  './assets/LittleYellowFlowerEducationFoundation2.jpg';
const LittleYellowFlowerEducationFoundation3 =
  './assets/LittleYellowFlowerEducationFoundation3.jpg';
const MalaysianRedCrescentSociety1 =
  './assets/MalaysianRedCrescentSociety1.jpg';
const Huckleberry1 = './assets/Huckleberry1.jpg';

const Bijibiji = './assets/Bijibiji.jpg';
const Huckleberry = './assets/Huckleberry.jpg';
const Kechara = './assets/KECHARA.png';
const Lyf = './assets/LYF.jpeg';
const MalaysianRedCrescent = './assets/MalaysianRedCrescent.png';

const kechara = {
  id: 1,
  title: 'Kechara x KFC',
  image: (
    <StaticImage
      src={Kechara}
      placeholder="blurred"
      style={{ height: '100%' }}
      objectFit="contain"
      objectPosition="center"
      alt="Kechara x KFC"
    />
  ),
  description:
    'Came together to feed the homeless In Kuala Lumpur during the pandemic.',
  bodyTitle: 'Finding innovative ways to help',
  bannerImages: [
    <StaticImage src={Kechara1} placeholder="blurred" alt="" />,
    <StaticImage src={Kechara2} placeholder="blurred" alt="" />,
    <StaticImage src={Kechara3} placeholder="blurred" alt="" />
  ],
  bodyDescription: (
    <div>
      <div>
        During the implementation of the Movement Control Order period, Kechara
        Soup Kitchen was forced to close it's doors but found new ways to
        provide relief to the homeless community.
      </div>
      <br /> <br />
      <div>
        GoGetters went above and beyond and helped sought out the homeless in
        various parts of the city to deliver fresh meals sponsored kindly by
        KFC.
      </div>
    </div>
  )
};
const huckleberry = {
  id: 2,
  title: 'Huckleberry',
  image: (
    <StaticImage
      src={Huckleberry}
      placeholder="blurred"
      style={{ height: '100%' }}
      objectFit="contain"
      objectPosition="center"
      alt="Huckleberry"
    />
  ),
  description:
    'Partnered up to help deliver cakes in appreciation to medical frontliners.',
  bannerImages: [
    <StaticImage src={Huckleberry1} placeholder="blurred" alt="" />
  ],
  bodyTitle: 'Appreciating our modern-day heroes',
  bodyDescription: (
    <div>
      <div>
        Being one of the few hospitals treating patients during the pandemic,
        Sungai Buloh Hospital’s staff worked around the clock.
      </div>
      <br /> <br />
      <div>
        Huckleberry showed their appreciation by hiring GoGetters to deliver
        cakes to frontliners and brighten their day.
      </div>
    </div>
  )
};
const biji = {
  id: 3,
  title: 'Biji Biji Initiative',
  image: (
    <StaticImage
      src={Bijibiji}
      placeholder="blurred"
      style={{ height: '100%' }}
      objectFit="contain"
      objectPosition="center"
      alt="Biji Biji"
    />
  ),
  description:
    'Helped deliver Personal Protective Equipment (PPE) to medical frontliners.',
  bannerImages: [
    <StaticImage src={Biji1} placeholder="blurred" alt="" />,
    <StaticImage src={Biji2} placeholder="blurred" alt="" />,
    <StaticImage src={Biji3} placeholder="blurred" alt="" />
  ],
  bodyTitle: 'Keeping our frontliners safe',
  bodyDescription: (
    <div>
      <div>
        Drove a campaign to "Protect Our Frontliners" by supplying them with
        personal protective equipment.
      </div>
      <br /> <br />
      <div>
        GoGet was extremely glad to be part of this meaningful initiative to
        help provide our medical frontliners with the necessary equipment to
        stay safe while fuelling the nation's fight against the pandemic.
      </div>
    </div>
  )
};
const malaysianRedCrescent = {
  id: 4,
  title: 'Malaysian Red Crescent Society',
  image: (
    <StaticImage
      src={MalaysianRedCrescent}
      placeholder="blurred"
      style={{ height: '100%' }}
      objectFit="contain"
      objectPosition="center"
      alt="Malaysian Red Crescent"
    />
  ),
  description: 'Provided an additional avenue to collect donations.',
  bannerImages: [
    <StaticImage
      src={MalaysianRedCrescentSociety1}
      placeholder="blurred"
      alt=""
    />
  ],
  bodyTitle: 'Providing our platform to help',
  bodyDescription: (
    <div>
      <div>
        We helped The Malaysian Red Crescent Society garner donations for
        essential equipment such as face masks, food items, ventilators, and
        funds via GoGet’s social media platforms.
      </div>
      <br /> <br />
      <div>
        These donations were channeled directly to The Malaysian Red Crescent
        Society.
      </div>
    </div>
  )
};
const lyf = {
  id: 5,
  title: 'Little Yellow Flower Education Foundation',
  image: (
    <StaticImage
      src={Lyf}
      placeholder="blurred"
      style={{ height: '100%' }}
      objectFit="contain"
      objectPosition="center"
      alt="Litle Yellow Flower Education Foundation"
    />
  ),
  description:
    'Helped to deliver groceries and essential items to needy families.',
  bannerImages: [
    <StaticImage
      src={LittleYellowFlowerEducationFoundation2}
      placeholder="blurred"
      alt=""
    />,
    <StaticImage
      src={LittleYellowFlowerEducationFoundation1}
      placeholder="blurred"
      alt=""
    />,
    <StaticImage
      src={LittleYellowFlowerEducationFoundation3}
      placeholder="blurred"
      alt=""
    />
  ],
  bodyTitle: 'Provided help to over 300 families',
  bodyDescription: (
    <div>
      <div>
        Many lost their jobs due to the Movement Control Order and struggled to
        put food on the table.{' '}
      </div>
      <br /> <br />
      <div>
        With the help of GoGetters, Little Yellow Flower provided groceries and
        daily essentials to more than 300 families in the Klang Valley.
      </div>
    </div>
  )
};

export { kechara, huckleberry, biji, malaysianRedCrescent, lyf };
