import React from 'react';
import TitleHeaderText from '../../components/TitleHeaderText';
import ProfileCard from '../../components/ProfileCardV2/index';
import { ProfileCardData } from './data';

function GogetPartners({ title, isHyperLink }) {
  return (
    <div>
      <TitleHeaderText
        text={title}
        styledMobile={{ marginLeft: '1rem', marginRight: '1rem' }}
      />
      <ProfileCard
        isHyperLink={isHyperLink}
        data={ProfileCardData}
        slider={true}
      />
    </div>
  );
}

export default GogetPartners;
