import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const AhmadSyamil = './assets/AhmadSyamil.png';
const Desmond = './assets/Desmond.png';
const Elsa = './assets/Elsa.png';
const Hazli = './assets/Hazli.png';
const Helmie = './assets/Helmie.png';
const Nadzrul = './assets/Nadzrul.png';
const Nala = './assets/Nala.png';
const Rosman = './assets/Rosman.png';
const See = './assets/See.png';
const Shukery = './assets/Shukery.png';
const Syafik = './assets/Syafik.png';
const Woo = './assets/Woo.png';

const rosman = {
  id: 1,
  name: 'Rosman',
  image: (
    <StaticImage src={Rosman} placeholder="blurred" alt="GoGetter Rosman" />
  ),
  description:
    '“I love contributing to the success of an event like a birthday party!”'
};
const nala = {
  id: 2,
  name: 'Nala',
  image: <StaticImage src={Nala} placeholder="blurred" alt="GoGetter Nala" />,
  description:
    '“As a flower lover, I was so excited to be part of a crew of 7 GoGetters to cut, prep and set up floral decorations for a wedding event.”'
};
const see = {
  id: 3,
  name: 'See',
  image: <StaticImage src={See} placeholder="blurred" alt="GoGetter See" />,
  description:
    '“Formerly in the corporate sector, being a GoGetter is a breath of fresh air for me due to the flexibility and ability to earn extra income!”'
};
const woo = {
  id: 4,
  name: 'Woon',
  image: <StaticImage src={Woo} placeholder="blurred" alt="GoGetter Woon" />,
  description:
    '“I have a passion for sales so telemarketing comes as second nature to me. GoGet allows me to explore job opportunities such as being a sales caller that fit my skills and expertise.”'
};
const syafik = {
  id: 5,
  name: 'Syafik',
  image: (
    <StaticImage src={Syafik} placeholder="blurred" alt="GoGetter Syafik" />
  ),
  description:
    "“I'm pursuing my actuarial science degree and joined GoGet to earn in my free time. My first job was rather interesting! I got to be a guide for a Poster's client who was looking to rent an event space.”"
};
const ahmadSyamil = {
  id: 6,
  name: 'Ahmad Syamil',
  image: (
    <StaticImage
      src={AhmadSyamil}
      placeholder="blurred"
      alt="GoGetter Ahmad Syamil"
    />
  ),
  description:
    '“An unforgetable GoGet job I had was to clean a family vacation home. The task seemed impossible but I felt a sense of fulfillment for completing it well.”'
};
const hazli = {
  id: 7,
  name: 'Hazli',
  image: <StaticImage src={Hazli} placeholder="blurred" alt="GoGetter Hazli" />,
  description:
    '“I became a catering helper for a Raya open house, which included set up, serving guests and helping to clean up after. I felt so appreciated when the Poster treated me to a lovely meal for my hard work.”'
};
const shukery = {
  id: 8,
  name: 'Shukery',
  image: (
    <StaticImage src={Shukery} placeholder="blurred" alt="GoGetter Shukery" />
  ),
  description:
    '“My favourite jobs on GoGet are food and shopping jobs. Being a personal shopper does not only build trust with customers but allows me to improve on my communication skills.”'
};
const desmond = {
  id: 9,
  name: 'Desmond',
  image: (
    <StaticImage src={Desmond} placeholder="blurred" alt="GoGetter Desmond" />
  ),
  description:
    '“I was in F&B and sales for over 9 years. My experience in the service industry taught me the importance of delivering top notch service for all GoGet jobs.”'
};
const helemie = {
  id: 10,
  name: 'Helmi',
  image: (
    <StaticImage src={Helmie} placeholder="blurred" alt="GoGetter Helmi" />
  ),
  description:
    '“I am an IT freelancer and I enjoy carrying out GoGet jobs in between projects!”'
};
const nadzrul = {
  id: 11,
  name: 'Nadzrul',
  image: (
    <StaticImage src={Nadzrul} placeholder="blurred" alt="GoGetter Nadzrul" />
  ),
  description:
    "“I delivered Mother's Day flowers and received sincere smiles. It's not about making money but making people happy.”"
};
const elsa = {
  id: 12,
  name: 'Elsa',
  image: <StaticImage src={Elsa} placeholder="blurred" alt="GoGetter Elsa" />,
  description:
    "“I'm a freelancer with experiences being a promoter and event coordinator. Hence, I joined GoGet as there are many different types of jobs I can do with my skills!”"
};

export {
  rosman,
  nala,
  see,
  woo,
  syafik,
  ahmadSyamil,
  hazli,
  shukery,
  desmond,
  helemie,
  nadzrul,
  elsa
};
